import React, { useEffect, useState, useContext } from "react";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import API from "../../utils/API";
import CseLayout from "./CseLayout";
import {
  ChartContainer,
  Semibold,
  StyledA,
  Button,
  LargeContainer,
  MediumContainer,
  Label,
  Input,
} from "../../utils/styled-components-library";
import Chart from "../../components/Chart";
import { UserContext } from "../../App";
import styled from "styled-components";
import * as Auth from "../../utils/Auth";
import config from "../../config";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import cn from "classnames";
import classes from "./CseResults.module.scss";
import CustomButton from "../../components/buttons/CustomButton";
import { toast } from "react-toastify";
import useCseSecondTurn from "../../hooks/useCseSecondTurn";
import { Form, Formik } from "formik";
import CseAttendance from "./CseAttendance";

const CseContainer = styled.div`
  justify-content: center;
`;

const Table = styled.table`
  margin: 0 auto;
  margin-bottom: 55px;
`;

const BoxButtons = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

const colors = [
  "#CEF9FD",
  "#79DAE8",
  "#7AA9E7",
  "#9AB3F5",
  "#A3D8F4",
  "#B9FFFC",
  "#B088F9",
  "#DA9FF9",
  "#EFFFFD",
  "#B8FFF9",
  "#85F4FF",
  "#42C2FF",
];

const RemainingSeats = (colleges) => {
  return (
    <section className={classes.HorizontalScrollArea}>
      <Table>
        <thead>
          <tr>
            <th
              style={{
                minWidth: 100,
                fontSize: 12,
                fontWeight: 400,
                color: "#667085",
                backgroundColor: "#F9FAFB",
                padding: "13px 24px",
              }}
            >
              Collège
            </th>
            <th
              style={{
                minWidth: 100,
                fontSize: 12,
                fontWeight: 400,
                color: "#667085",
                backgroundColor: "#F9FAFB",
                padding: "13px 24px",
              }}
            >
              Sièges titulaire vacants
            </th>
            <th
              style={{
                minWidth: 100,
                fontSize: 12,
                fontWeight: 400,
                color: "#667085",
                backgroundColor: "#F9FAFB",
                padding: "13px 24px",
              }}
            >
              Sièges suppléants vacants
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(colleges).map(function (key, index) {
            return (
              <tr key={index} key={index}>
                <td className={classes.TableCell}>{key}</td>
                <td className={classes.TableCell}>{colleges[key]["Titulaire"]}</td>
                <td className={classes.TableCell}>{colleges[key]["Suppléant"]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
};

const calculatePercentage = (ratio) => {
  const result =
    parseFloat(ratio * 100)
      .toFixed(2)
      .replace(".", ",") + "%";
  return result.includes("NaN") ? "0,00%" : result;
};

const ProVoteTabs = withStyles({
  indicator: {
    backgroundColor: "#1D529C",
  },
})((props) => <Tabs variant="scrollable" scrollButtons="auto" {...props} />);

const ProVoteTab = withStyles((theme) => ({
  root: {
    "&$selected": {
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`voters-list-tab-panel-${index}`}
      aria-labelledby={`voters-list-tab-${index}`}
      className={classes.TabPanel}
    >
      {children}
    </div>
  );
};

const sexTranslation = (sex) => {
  if (sex === "male") return "Monsieur";
  if (sex === "female") return "Madame";
  return sex;
};

const Candidate = ({ candidate, hasCandidate, voteByList }) => {
  return (
    <div className={cn(classes.CandidateVote)}>
      <span className={cn(classes.CandidateVote_Name)}>
        {(hasCandidate === 0 && <>{candidate.listName}</>) ||
          (voteByList === 1 && (
            <>
              {candidate.candidateFirstname} {candidate.candidateLastname}{" "}
              {`${candidate.score} Suffrage(s)`}
            </>
          )) || (
            <>
              {sexTranslation(candidate.candidateSex)} {candidate.candidateFirstname}{" "}
              {candidate.candidateLastname}
              {candidate.candidateCustomField ? `(${candidate.candidateCustomField})` : ""}
            </>
          )}
      </span>
      <div className={classes.CandidateVote_Images}>
        <span>
          {hasCandidate === 0
            ? `${candidate.assignedSeat} siège(s)`
            : voteByList === 1
            ? `${candidate.assignedSeat} siège(s)`
            : `${candidate.score} Suffrage(s)`}
        </span>
      </div>
    </div>
  );
};

const PrintCandidates = ({ candidates, voteByList }) => {
  return (
    <div>
      <div className={classes.ElectionTypeName}>{candidates.collegeListElectionName}</div>

      {Object.values(candidates.winners).map((candidate, index) => {
        return candidate ? (
          <div className={classes.CandidateBox} key={index}>
            <Candidate
              style={{ paddingBottom: "12px" }}
              candidate={candidate}
              hasCandidate={candidates.hasCandidate ? true : false}
              voteByList={voteByList}
            ></Candidate>
          </div>
        ) : (
          <></>
        );
      })}
    </div>
  );
};

const PrintLists = ({ colleges, voteByList }) => {
  return (
    <div style={{ paddingBottom: "30px" }}>
      <div className={classes.CollegeName}>Collège : {colleges.collegeName}</div>
      {Object.values(colleges).map((electionType, index) => {
        if (typeof electionType !== "string") {
          return (
            <PrintCandidates
              key={index}
              candidates={electionType}
              voteByList={voteByList}
            ></PrintCandidates>
          );
        }
      })}
    </div>
  );
};

const ResultsByLists = (Results) => {
  let [selectedTab, setSelectedTab] = useState(0);

  const createTabProps = (index) => {
    return {
      id: `voters-list-tab-${index}`,
      "aria-controls": `voters-list-tab-panel-${index}`,
    };
  };

  const onTabChangeHandler = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const data = Object.values(Results?.results?.listsResults);
  const voteByList = Results?.results?.voteByList;
  const voteBySigle = Results?.results?.collegesWithList[0]?.lists[0]?.hasCandidate === 0;

  return (
    <LargeContainer>
      <Semibold style={{ fontSize: 20, marginBottom: 50 }}>
        {voteBySigle ? "Sigle(s) élu(s)" : voteByList ? "Liste(s) élue(s)" : "Élu(s) par liste(s)"}
      </Semibold>
      <ProVoteTabs value={selectedTab} onChange={onTabChangeHandler} aria-label="Voters list tabs">
        {data.map((list, index) => {
          return <ProVoteTab label={list.listName} {...createTabProps(index)} />;
        })}
      </ProVoteTabs>
      {data.map((list, index) => {
        const listArray = Object.values(list);
        return (
          <TabPanel value={selectedTab} index={index}>
            {listArray.map((college, ind) => {
              return (
                <div key={ind}>
                  {college.collegeName && (
                    <PrintLists colleges={college} voteByList={voteByList}></PrintLists>
                  )}
                </div>
              );
            })}
          </TabPanel>
        );
      })}
    </LargeContainer>
  );
};

const PdfResultByList = (Results) => {
  const data = Object.values(Results?.results?.listsResults);
  const voteByList = Results?.results?.voteByList;

  return (
    <LargeContainer>
      <Semibold style={{ fontSize: 20, marginBottom: 50 }}>Élus par liste</Semibold>
      {data.map((list, index) => {
        const listArray = Object.values(list);
        return (
          <React.Fragment key={index}>
            <Semibold style={{ fontSize: 25, marginBottom: 15 }}>{list.listName}</Semibold>
            {listArray.map((college, index) => {
              return (
                <div key={index}>
                  {college.collegeName && (
                    <PrintLists colleges={college} voteByList={voteByList}></PrintLists>
                  )}
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
    </LargeContainer>
  );
};

const CollegeDataTable = ({ cseResults, isVisible, printing, user }) => {
  const collegeDataHeaders = [
    "Collèges",
    "Nombre d'inscrits",
    "Nombre de votants",
    "Taux de participation",
    "Quorum atteint",
    "Nombre de blanc",
    "Suffrages valablement exprimés",
    "Quotient électoral",
  ];

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Semibold style={{ fontSize: 20, marginBottom: 20 }}>Informations par collèges</Semibold>
      <section>
        {(cseResults?.isPublished === 0 &&
          user.userType != "admin" &&
          user.userType != "client" && (
            <>Certaines données ne sont accessibles qu'après avoir publié les résultats</>
          )) ||
          null}
      </section>
      <div className={classes.HorizontalScrollArea}>
        <Table>
          <thead>
            <tr>
              {collegeDataHeaders.map((head) => (
                <th
                  key={head}
                  style={{
                    minWidth: 100,
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#667085",
                    backgroundColor: "#F9FAFB",
                    padding: "13px 24px",
                  }}
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {cseResults?.elections?.map((election) =>
              election?.colleges?.map((college) => {
                if (college.totalSeat > 0) {
                  return (
                    <tr key={college.collegeName}>
                      <td className={classes.TableCell}>
                        {college.collegeName} - {election.electionType}
                      </td>
                      <td className={classes.TableCell}>{college.registeredVotersTotal}</td>
                      <td className={classes.TableCell}>{college.allVotesNumber}</td>
                      <td className={classes.TableCell}>{college.votesPercentage}</td>
                      <td className={classes.TableCell}>
                        {(!cseResults.isFiltered && (
                          <>
                            {cseResults.quorumNeeded > 0 ? (
                              <>
                                {isVisible || printing
                                  ? college.quorumRatio >= cseResults.quorumNeeded
                                    ? "Atteint"
                                    : "Non atteint"
                                  : "-"}{" "}
                                {isVisible || printing ? `(${college.quorumRatioPercentage})` : ""}
                              </>
                            ) : (
                              "-"
                            )}
                          </>
                        )) ||
                          "-"}
                      </td>
                      <td className={classes.TableCell}>
                        {isVisible || printing ? college.blankVotesTotal : "-"}
                      </td>
                      <td className={classes.TableCell}>
                        {isVisible || printing ? college.eligibleVotesNumber : "-"}
                      </td>
                      <td className={classes.TableCell}>
                        {(!cseResults.isFiltered && (
                          <>
                            {isVisible || printing
                              ? election.electionType === "Suppléant"
                                ? college.substituteElectionQuotien || 0
                                : college.titularElectionQuotien || 0
                              : "-"}
                          </>
                        )) ||
                          null}
                      </td>
                    </tr>
                  );
                }
                return null;
              })
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const BigSuffrageDataTable = ({ cseResults, cseResultsRefacto, listsResults }) => {
  const collegeDataHeaders = [
    "Collèges",
    "Nombre d'inscrits",
    "Nombre de votants",
    "Taux de participation",
    "Taux de participation",
    "Quorum atteint",
    "Nombre de blanc",
    "Suffrages valablement exprimés",
  ];
  return (
    <div className={classes.HorizontalScrollArea}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            justifyContent: "center",
          }}
        >
          <table
            style={{
              marginBottom: "55px",
              borderCollapse: "collapse",
            }}
          >
            <tbody>
              <tr key={cseResultsRefacto.collegeName}>
                <th
                  style={{
                    minWidth: 100,
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#667085",
                    padding: "13px 24px",
                  }}
                ></th>
                {cseResultsRefacto?.map((college, ind) => (
                  <th key={ind} colSpan="2">
                    <div
                      style={{
                        minWidth: 100,
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#FFF",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor: "#17427D",
                        padding: "7px 24px",
                      }}
                    >
                      {college.collegeName} - {college.electionType}
                    </div>
                  </th>
                ))}
              </tr>
              <tr>
                <th
                  style={{
                    minWidth: 100,
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#667085",
                    backgroundColor: "#F9FAFB",
                    padding: "13px 24px",
                    border: "1px solid #EAECF0",
                    borderRadius: "5px 0 0 0",
                  }}
                >
                  Listes
                </th>
                {cseResultsRefacto?.map((college, ind, arr) => (
                  <React.Fragment key={ind}>
                    <th
                      style={{
                        minWidth: 100,
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#667085",
                        backgroundColor: "#F9FAFB",
                      }}
                    >
                      Suffrages valablement exprimés
                    </th>
                    <th
                      style={{
                        minWidth: 100,
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#667085",
                        backgroundColor: "#F9FAFB",
                        borderRight: "1px solid #EAECF0",
                      }}
                    >
                      Pourcentages
                    </th>
                  </React.Fragment>
                ))}
              </tr>
              {cseResults?.collegeListNames?.map((list, ind) => (
                <tr key={ind}>
                  <td
                    style={{
                      borderBottom: "1px solid #EAECF0",
                      borderLeft: "1px solid #EAECF0",
                      borderRight: "1px solid #EAECF0",

                      fontSize: 14,
                      fontWeight: 400,
                      color: "#667085",
                      padding: "26px 24px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {list.name}
                  </td>
                  {listsResults?.map((lists, index) => {
                    const currentList = lists.filter(
                      (currentList) => currentList.collegeListName === list.name
                    );
                    const totalValidVote = currentList.reduce((accumulator, object) => {
                      return accumulator + object.groupedValidVotesNb;
                    }, 0);
                    const listRatioPercentage = currentList.reduce((accumulator, object) => {
                      return accumulator + object.listRatio;
                    }, 0.0);
                    return (
                      <React.Fragment key={index}>
                        <td
                          style={{
                            borderBottom: "1px solid #EAECF0",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#667085",
                            padding: "26px 24px",
                          }}
                        >
                          {totalValidVote}
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid #EAECF0",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#667085",
                            padding: "26px 24px",
                            borderRight: "1px solid #EAECF0",
                          }}
                        >
                          {calculatePercentage(listRatioPercentage)}
                        </td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
              <tr>
                <td
                  style={{
                    borderBottom: "1px solid #EAECF0",
                    borderLeft: "1px solid #EAECF0",
                    borderRight: "1px solid #EAECF0",

                    fontSize: 14,
                    fontWeight: 400,
                    color: "#667085",
                    padding: "26px 24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Total
                </td>
                {cseResultsRefacto?.map((college, ind, arr) => (
                  <React.Fragment key={ind}>
                    <td className={classes.TableCell}>{college?.eligibleVotesNumber}</td>
                    <td
                      style={{
                        borderBottom: "1px solid #EAECF0",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#667085",
                        padding: "26px 24px",
                        borderRight: "1px solid #EAECF0",
                      }}
                    >
                      {college?.eligibleVotesNumber
                        ? parseFloat(
                            (college?.eligibleVotesNumber / college?.eligibleVotesNumber) * 100
                          ).toFixed(2)
                        : "0,00"}
                      %
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Attendance = (result) => {
  return (
    <Table>
      <thead>
        <tr>
          <th
            style={{
              minWidth: 100,
              fontSize: 12,
              fontWeight: 400,
              color: "#667085",
              backgroundColor: "#F9FAFB",
              padding: "13px 24px",
            }}
          >
            {"Taux de participation général"}
          </th>
          <th
            style={{
              minWidth: 100,
              fontSize: 12,
              fontWeight: 400,
              color: "#667085",
              backgroundColor: "#F9FAFB",
              padding: "13px 24px",
            }}
          >
            {"Nombre de quorum(s) atteint (par scrutin)"}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>{result.totalParticipationPercentage}</td>
          <td>{`${result.quorumScrutin} / ${result.totalScrutin}`}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const CseResults = ({
  match,
  printing,
  setLoaded,
}: {
  match: any;
  printing: any;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);

  if (printing) {
    return (
        <CseResultsAfterStripping match={match} printing={true} setLoaded={setLoaded} />
    );
  }

  return (
    <>
      {cse && cse.isValidKey ? (
        <CseResultsAfterStripping match={match} printing={false} setLoaded={setLoaded} />
      ) : (
        <CseAttendance match={match} result={true} />
      )}
    </>
  );
};

const SyndicalRepresentativity = ({ syndicalListResult, syndicalListArray }) => {
  const lists = syndicalListArray[0].lists.map((data) => {
    return data.list;
  });
  return (
    <>
      <Semibold style={{ fontSize: 20, marginBottom: 50 }}>Représentativité syndicale</Semibold>

      <Table>
        <thead>
          <tr>
            <th></th>
            {syndicalListArray.map((header) => (
              <th>
                <div
                  style={{
                    minWidth: 100,
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#FFF",
                    borderRadius: "5px 5px 0 0",
                    backgroundColor: "#17427D",
                    padding: "7px 24px",
                  }}
                >
                  {header.collegeName === "Total"
                    ? "Total"
                    : header.collegeName === "Représentativité"
                    ? "Représentativité"
                    : `Collège : ${header.collegeName}`}
                </div>
              </th>
            ))}
          </tr>
          <tr>
            <th
              style={{
                minWidth: 100,
                fontSize: 12,
                fontWeight: 400,
                color: "#667085",
                backgroundColor: "#F9FAFB",
                padding: "13px 24px",
              }}
            >
              Listes
            </th>
            {syndicalListArray.map((item) => (
              <th>
                <div
                  style={{
                    minWidth: 100,
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#667085",
                    backgroundColor: "#F9FAFB",
                    padding: "13px 24px",
                  }}
                >
                  {(item.totalScore === "100%" && <>Pourcentage</>) || (
                    <>
                      <strong>{item.totalScore}</strong> Suffrages
                    </>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {lists.map((list) => {
            return (
              <tr>
                <td>{list}</td>
                {syndicalListArray.map((college) => {
                  const listData = college.lists.filter((x) => {
                    return x.list === list;
                  });
                  return listData.map((x) => {
                    if (x.scoreRatioFinal) {
                      return <td>{calculatePercentage(x.scoreRatio)}</td>;
                    } else {
                      return <td>{x.score}</td>;
                    }
                  });
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Semibold style={{ fontSize: 20, marginBottom: 50 }}>
        Représentativité syndicale finale
      </Semibold>
      <Table>
        <thead>
          <th
            style={{
              minWidth: 100,
              fontSize: 12,
              fontWeight: 400,
              color: "#667085",
              backgroundColor: "#F9FAFB",
              padding: "13px 24px",
            }}
          >
            Listes
          </th>
          <th
            style={{
              minWidth: 100,
              fontSize: 12,
              fontWeight: 400,
              color: "#667085",
              backgroundColor: "#F9FAFB",
              padding: "13px 24px",
            }}
          >
            Représentativité (%)
          </th>
        </thead>
        <tbody>
          {lists.map((list) => {
            const listData = syndicalListResult.find((x) => {
              return x.list === list;
            });
            return (
              <tr>
                <td>{list}</td> <td>{listData?.scoreRatioFinal || 0}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const CseResultsAfterStripping = ({
  match,
  printing,
  setLoaded,
}: {
  match: any;
  printing: any;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const cseSecondTurn = useCseSecondTurn(cseId);
  const [cseResults, setCseResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNeededSecondTurn, setIsNeededSecondTurn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [collegeNumber, setCollegeNumber] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const user = useContext(UserContext);

  const getResultData = () => {
    API.get(
      "/getCseResultsData",
      { cseId },
      (data: { success: boolean; data: any; isReady: boolean }) => {
        setIsReady(data.isReady);

        if (data.isReady) {
          const { cseParentId, isValidKey, isPublished, collegesWithList } = data.data;
          setTitle(cseParentId ? "Résultats 2nd tour" : "Résultats 1er tour");
          setCseResults(data.data);
          checkSecondTurn(data.data);
          setCollegeNumber(collegesWithList.length);
          setIsVisible(
            printing ||
              (isValidKey === 1 && (user.userType === "admin" || user.userType === "client")) ||
              isPublished === 1
          );
          if (setLoaded) setLoaded(true);
        } else if (!printing) {
          setTimeout(getResultData, 10000);
        }
        setIsFullyLoaded(true);
      }
    );
  };

  useEffect(() => {
    getResultData();
  }, []);

  const buttonParams =
    isVisible && !cse?.isDisabledDownload
      ? {
          target: "_blank",
          rel: "noreferrer",
          href: `${
            config.apiUrl
          }/cseResultsReportXLS.xlsx?cseId=${cseId}&token=${Auth.getToken()}&filters=${encodeURIComponent(
            JSON.stringify(cseResults.filters)
          )}`,
        }
      : {};

  const checkSecondTurn = (cseResults) => {
    Object.values(cseResults?.collegeSeats).forEach((item) => {
      if (item?.Titulaire && item?.Titulaire !== 0) {
        setIsNeededSecondTurn(true);
      }
      if (item?.Suppléant && item?.Suppléant !== 0) {
        setIsNeededSecondTurn(true);
      }
    });
    return setIsNeededSecondTurn(!cseResults?.isQuorum);
  };

  const cseResultsRefacto =
    cseResults?.elections
      ?.flatMap((election, ind1) =>
        election.colleges.map((college, ind2) => {
          if (college.totalSeat > 0) {
            return {
              ...college,
              electionType: election.electionType,
              color: colors[2 * ind1 + ind2],
            };
          }
        })
      )
      .filter((x) => {
        return x !== undefined;
      }) || [];

  const listsResults = cseResultsRefacto?.map((college) => college.lists) || [];

  const getCollegeDataset = () => {
    const dataset = cseResults?.collegeListNames?.map((list: any, idx: number) => {
      const data = cseResultsRefacto?.map((college: any) => {
        const currentList = college.lists.filter(
          (currentList) => currentList.collegeListName === list.name
        );
        const totalValidVote = currentList.reduce((accumulator, object) => {
          return accumulator + object.groupedValidVotesNb;
        }, 0);

        return totalValidVote;
      });
      const total = cseResultsRefacto?.map((college: any) => {
        return college.allVotesNumber;
      });
      return {
        label: list.name,
        totals: total,
        data: data,
        backgroundColor: list.color,
        stack: "Stack 0",
      };
    });
    let blankVotes = [];
    cseResultsRefacto?.forEach((element) => {
      blankVotes.push(element.blankVotesTotal);
    });

    dataset?.push({
      label: "Votes blanc",
      data: blankVotes,
      backgroundColor: "#EFEFEF",
      stack: "Stack 0",
      totals: cseResultsRefacto?.map((college: any) => {
        return college.allVotesNumber;
      }),
    });

    return dataset;
  };

  const getSuffrageDataset = () => {
    let total = 0;
    let dataset = cseResultsRefacto?.map((college, ind) => {
      let idx = 0;
      const data = cseResults?.collegeListNames?.map((list) => {
        const currentList = college.lists.filter(
          (currentList) => currentList.collegeListName === list.name
        );
        const totalValidVote = currentList.reduce((accumulator, object) => {
          return accumulator + object.groupedValidVotesNb;
        }, 0);

        return totalValidVote;
      });

      const t = listsResults?.map((lists, index) => {
        const currentList = lists.filter(
          (currentList) => currentList.cseCollegeId === college.cseCollegeId
        );
        const totalValidVote = currentList.reduce((accumulator, object) => {
          return accumulator + object.groupedValidVotesNb;
        }, 0);
        return totalValidVote;
      });
      total += data.reduce((a, b) => a + b);
      return {
        label: `${college.collegeName} - ${college.electionType}`,
        data: data,
        backgroundColor: colors[2 * ind + idx],
        stack: "Stack 0",
      };
    });

    dataset?.forEach((element, i) => {
      element.totals = Array(dataset[i].data.length).fill(total);
    });

    return dataset;
  };

  const getFilters = () => {
    const filterFields = [
      ...new Set(cseResults.availableFilter[0].map((item) => item.filterField)),
    ];

    let filters = filterFields.map((field) => {
      return {
        field,
        values: cseResults.availableFilter[0].filter((x) => {
          if (x.filterField === field) return x.filterValue;
        }),
      };
    });

    filters = filters.map((field) => {
      field.values.unshift({ filterValue: "Ne pas filtrer" });
      return field;
    });

    const initialValues = Object.fromEntries(
      filters.map((x) => {
        return [x.field, "Ne pas filtrer"];
      })
    );
    return (
      <>
        {(filters.length && (
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              return {};
            }}
            onSubmit={(values) => {
              const data = new URLSearchParams(values).toString();
              API.get(
                "/getCseResultsData",
                { cseId, filters: data },
                async (data: { success: boolean; data: any; isReady: boolean }) => {
                  setIsReady(data.isReady);
                  if (data.isReady) {
                    setTitle(data.data.cseParentId ? "Résultats 2nd tour" : "Résultats 1er tour");
                    setCseResults(data.data);
                    checkSecondTurn(data.data);
                    setCollegeNumber(data.data.collegesWithList.length);
                    setIsVisible(
                      (cse?.isValidKey === 1 &&
                        (user.userType === "admin" || user.userType === "client")) ||
                        cse?.isPublished === 1
                    );
                  }

                  setIsFullyLoaded(true);
                }
              );
            }}
          >
            {({ resetForm }) => (
              <Form>
                {filters.map((filter, index) => {
                  return (
                    <div key={index}>
                      <Label style={{ textTransform: "capitalize" }}>{filter.field}</Label>
                      <Input component="select" name={filter.field}>
                        {filter.values.map((x) => (
                          <option key={x.filterValue} value={x.filterValue}>
                            {x.filterValue}
                          </option>
                        ))}
                      </Input>
                    </div>
                  );
                })}
                <BoxButtons>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={classes.CseConnexionButton}
                  >
                    Filtrer
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                    }}
                    type="submit"
                    disabled={isSubmitting}
                    className={classes.CseConnexionButton}
                  >
                    Réinitialiser les filtres
                  </Button>
                </BoxButtons>
              </Form>
            )}
          </Formik>
        )) ||
          null}
      </>
    );
  };

  return (
    <React.StrictMode>
      {cse && (
        <CseContainer>
          {!printing && (
            <>
              <CseHeader cse={cse} selectedTab={"resultats"} />
              <CseLayout
                title={title}
                loading={!isFullyLoaded}
                NoResultText={"Le vote n’a pas encore démarré"}
                showResults={!!cse}
                visible={true}
                subTitleAction={(cseResults?.availableFilter && getFilters()) || <></>}
                additionalActions={
                  isFullyLoaded && (
                    <div style={{ gap: "5px", display: "grid" }}>
                      <StyledA {...buttonParams}>
                        {isVisible && !cse.isDisabledDownload ? (
                          <Button>Télécharger au format Excel</Button>
                        ) : (
                          <Button disabled>Télécharger au format Excel</Button>
                        )}
                      </StyledA>

                      {isVisible ? (
                        <StyledA
                          href={`${config.apiUrl}/cseResultsReportPDF.pdf?cseId=${
                            cse?.cseId
                          }&token=${Auth.getToken()}`}
                          target="_blank"
                        >
                          <Button>Télécharger au format PDF</Button>
                        </StyledA>
                      ) : (
                        <StyledA href="#" target="_blank">
                          <Button disabled>Télécharger au format PDF</Button>
                        </StyledA>
                      )}

                      {(cse?.cseParentId === null &&
                        cseResults?.isNeededSecondTurn &&
                        (user.userType === "client" || user.userType === "clientAdmin") &&
                        cseSecondTurn?.cseId === undefined && (
                          <CustomButton
                            type="submit"
                            invert={true}
                            disabled={isSubmitting}
                            onClick={() => {
                              setIsSubmitting(true);
                              API.post(
                                "/cseRouteCRUD",
                                {
                                  cseId: cse.cseId,
                                  seat: JSON.stringify(cseResults.collegeSeats),
                                  module: "createCseSecondTurn",
                                },
                                (data: { success: boolean; data: any }) => {
                                  if (data.success) {
                                    toast.success(`Le second tour a été créé avec succès !`);
                                  }
                                },
                                () => {
                                  setIsSubmitting(false);
                                  toast.error(`Le second tour n'a pas été créé !`);
                                }
                              );
                            }}
                          >
                            Créer un second tour
                          </CustomButton>
                        )) ||
                        null}

                      {((cse?.cseParentId || !cseResults?.isNeededSecondTurn) &&
                        !cse.isDisabledDownload && (
                          <StyledA
                            href={`${config.apiUrl}/cseCandidateResultExport.xlsx?cseId=${
                              cse?.cseId
                            }&token=${Auth.getToken()}`}
                            target="_blank"
                          >
                            <Button>Télécharger la liste des élus</Button>
                          </StyledA>
                        )) ||
                        null}
                    </div>
                  )
                }
              >
                {isVisible &&
                  cseResults?.isNeededSecondTurn &&
                  !cse?.cseParentId &&
                  !cseResults.isFiltered &&
                  isReady && (
                    <div className={classes.SecondTurnNeed}>Un second tour est necessaire</div>
                  )}
              </CseLayout>
            </>
          )}
          {(isReady && (
            <>
              {(isFullyLoaded || printing) && (
                <>
                  {(isVisible || printing) && (
                    <>
                      <div id="data-loaded"/>
                      {!cseResults.isFiltered && (
                        <section style={{ marginBottom: 50 }}>
                          <Attendance {...cseResults} />
                        </section>
                      )}

                      {!cseResults.isFiltered && (
                        <section>
                          <Semibold style={{ fontSize: 20, marginBottom: 50 }}>
                            Nombre de sièges vacants
                          </Semibold>
                          <RemainingSeats {...cseResults?.collegeSeats}></RemainingSeats>
                        </section>
                      )}
                    </>
                  )}
                  {((cse.disableParticipationdisplay === 0 || isVisible) && (
                    <CollegeDataTable
                      user={user}
                      isVisible={isVisible}
                      printing={printing ? true : false}
                      cseResults={cseResults}
                    />
                  )) ||
                    null}

                  {((isVisible || printing) && (
                    <div>
                      <section
                        style={
                          printing
                            ? {
                                display: "flex",
                                flexDirection: "column",
                                width: "70vw",
                                justifyContent: "start",
                              }
                            : {
                                display: "flex",
                                flexDirection: "column",
                                width: "100vw",
                                justifyContent: "center",
                              }
                        }
                      >
                        <ChartContainer>
                          <Chart
                            isStacked
                            showLegend
                            chartType={"horizontalBars"}
                            displayType={"both"}
                            // count
                            data={getCollegeDataset()}
                            // label
                            labels={cseResultsRefacto?.map(
                              (college: any) => `${college.collegeName} - ${college.electionType}`
                            )}
                          />
                        </ChartContainer>
                      </section>

                      <div style={{ display: "block", breakBefore: "page" }}>
                        <Semibold style={{ fontSize: 20, marginBottom: 50 }}>
                          Décompte des suffrages exprimés par listes
                        </Semibold>
                        <section
                          style={
                            printing
                              ? collegeNumber === 5
                                ? {
                                    zoom: 0.65,
                                  }
                                : collegeNumber === 4
                                ? {
                                    zoom: 0.75,
                                  }
                                : {
                                    zoom: 0.85,
                                  }
                              : {}
                          }
                        >
                          <BigSuffrageDataTable
                            cseResultsRefacto={cseResultsRefacto}
                            listsResults={listsResults}
                            cseResults={cseResults}
                          />
                        </section>

                        <section
                          style={
                            printing
                              ? {
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "70vw",
                                  justifyContent: "start",
                                }
                              : {
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100vw",
                                  justifyContent: "center",
                                }
                          }
                        >
                          <ChartContainer>
                            <Chart
                              isStacked
                              showLegend
                              chartType={"horizontalBars"}
                              displayType={"both"}
                              // count
                              data={getSuffrageDataset()}
                              // label
                              labels={cseResults?.collegeListNames?.map((list) => list.name) || []}
                              colors={cseResults?.collegeListNames?.map((list) => list.color) || []}
                            />
                          </ChartContainer>
                        </section>
                      </div>

                      {cseResults?.syndicalListArray &&
                        cseResults?.syndicalListResult &&
                        cse?.cseParentId === null && (
                          <SyndicalRepresentativity
                            syndicalListArray={cseResults?.syndicalListArray}
                            syndicalListResult={cseResults?.syndicalListResult}
                          />
                        )}

                      {(!cseResults.isFiltered && (
                        <>
                          {!printing ? (
                            <ResultsByLists results={cseResults} />
                          ) : (
                            <div style={{ display: "block", breakBefore: "page" }}>
                              {cseResults?.listsResults && <PdfResultByList results={cseResults} />}
                            </div>
                          )}
                        </>
                      )) ||
                        null}
                    </div>
                  )) || <div>Les résultats seront affichés après la clôture des votes.</div>}
                </>
              )}
            </>
          )) || (
            <div style={{ marginTop: 50 }}>
              {(cse.isValidKey && (
                <>Calcul des résultats en cours, cela peut prendre plusieurs minutes.</>
              )) || <>Le dépouillement n'est pas terminé.</>}
            </div>
          )}
        </CseContainer>
      )}
    </React.StrictMode>
  );
};

export default CseResults;
